// "id": Number,
// "text": String,
// "data": Object,
// "children": Array,
// "state": Object
import Monitoring from '@/entity/Monitoring'
export default class Node {
  constructor(o = {}) {
    const me = o || {};
    this.id = me.id || undefined;

    const data = me.test || me.data;
    const name = me.name || me.text || (data || {}).name || (data || {}).text || 'Untitled Test';
    const items = me.items || me.children;
    console.log("this.data ", this.data);

    this.text = name;
    this.syskey = me.syskey;
    this.data = data ? new Monitoring(data) : {};
    this.children = items || [];
    this.state = me.state || {
      dropable: false
    };

    console.log("data ", data);
    console.log("constructor ", this);
    // States: 
    // "selected": false,
    // "selectable": true,
    // "checked": false,
    // "expanded": false,
    // "disabled": false,
    // "visible": true,
    // "indeterminate": false,
    // "matched": false,
    // "editable": true,
    // "dragging": false,
    // "draggable": true,
    // "dropable": true
  }
}