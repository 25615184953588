<template>
  <div class="test-item flex">
    <div class="node-controls">
      <vs-dropdown ref="vsDropdown" vs-trigger-click vs-drop-right>
        <vs-button radius type="flat" icon="icon-more-vertical" icon-pack="feather" />
        <vs-dropdown-menu class="node-controls__dropdown">
          <vs-dropdown-item
            @click.stop="
              $refs.vsDropdown.$refs.dropdown.click();
              $emit('on-new-item');
            "
            v-if="node.hasChildren() || node.isRoot()"
          >
            <span class="flex items-center text-xs">
              <feather-icon icon="FilePlusIcon" svgClasses="h-4 w-4 mr-2 stroke-current" />
              <span>Add Test</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item
            @click.stop="
              $refs.vsDropdown.$refs.dropdown.click();
              $emit('on-new-parent');
            "
            v-if="node.hasChildren() || node.isRoot()"
          >
            <span class="flex items-center text-xs">
              <feather-icon icon="FolderPlusIcon" svgClasses="h-4 w-4 mr-2 stroke-current" />
              <span>Add Subsection</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item
            @click.stop="
              $refs.vsDropdown.$refs.dropdown.click();
              $emit('on-delete');
            "
            color="danger"
          >
            <span class="flex items-center text-xs">
              <feather-icon icon="Trash2Icon" svgClasses="h-4 w-4 mr-2 stroke-current" />
              <span>Delete</span>
            </span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <div ref="nodeContent" class="node-content flex-grow pl-3 pt-2">
      <vxEditable
        v-if="node.hasChildren() || node.isRoot()"
        class="font-semibold -mt-2"
        ref="parentTitle"
        v-model="node.text"
        @input="value => $emit('changed', value)"
      />
      <div class="node-child" v-else>
        <div v-if="node.index() === 0" class="node-content__head">
          <div class="content-heading">Key</div>
          <div class="content-heading">Test</div>
          <div class="content-heading">Last Ran</div>
          <div class="content-heading">Last Result</div>
        </div>
        <div class="node-content__body">
          <div class="content-value font-semibold">{{ (node.data || {}).key }}</div>
          <div class="content-value">
            <vxEditable
              label="text"
              type="monitor"
              v-model="node.data"
              class="-mt-2"
              ref="childTitle"
              :inline="true"
              @input="value => $emit('changed', value)"
            />
          </div>
          <div class="content-value">{{ (node.data || {}).lastRan || 'N/a' }}</div>
          <div class="content-value">
            {{ (node.data || {}).lastResult || 'N/a' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    node: {
      required: true
    }
  }
};
</script>
