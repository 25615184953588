<template>
  <section class="test-case-builder overflow-visible">
    <div v-if="treeEmpty" class="con-vs-alert con-vs-alert-primary con-icon">
      <div class="vs-alert con-icon">
        <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
        <div class="flex items-center justify-between">
          <p>Create you first test section</p>
          <vs-button color="success" @click="appendParentNode(tree)" icon-pack="feather" icon="icon-plus" size="small">Add Root Section</vs-button>
        </div>
      </div>
    </div>
    <div v-else class="flex mb-2">
      <vs-button color="success" @click="appendParentNode(tree)" icon-pack="feather" icon="icon-plus" size="small">Add Root Section</vs-button>
    </div>
    <tree ref="tree" class="vx-tree" :data="value" :options="treeOptions">
      <div
        slot-scope="{ node }"
        class="tree-slot w-full"
        :class="[node.hasChildren() || node.isRoot() ? 'tree-slot--parent' : 'tree-slot--child', `tree-slot--index-${node.index()}`]"
      >
        <ItemRow
          ref="itemRow"
          :node="node"
          @on-new-item="prependNode(node)"
          @on-new-parent="appendParentNode(node)"
          @on-delete="deleteNode(node)"
          @changed="emitInput"
        />
      </div>
    </tree>
  </section>
</template>

<script>
import Tree from 'liquor-tree';
import Node from './node';
import ItemRow from '@/views/monitoring/components/test-suite-builder/ItemRow';
import TestSuite from '@/entity/TestSuite';
import SingleMonitorForm from '@/views/monitoring/components/SingleMonitorForm';
export default {
  components: { Tree, ItemRow },
  props: {
    value: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  data() {
    const { $vs } = this;
    return {
      treeOptions: {
        // propertyNames: {
        //   text: 'name',
        //   children: 'items',
        //   data: 'test'
        // },
        parentSelect: true,
        checkbox: true,
        keyboardNavigation: false,
        dnd: {
          onDragFinish(targetNode, destinationNode, dropPosition) {
            const clonedTargetNode = new Node({
              ...targetNode,
              state: {
                dropable: false
              }
            });
            if (targetNode.hasChildren()) {
              console.log('A');
              if (
                !destinationNode.hasChildren() &&
                ((targetNode.parent || {}).id === (destinationNode.parent || {}).id || destinationNode.index() >= 0)
              )
                return false;
            }
            if (((targetNode.parent || {}).children || []).length === 1) {
              console.log('B', dropPosition);
              const dest = !destinationNode.isRoot() ? destinationNode.prev() : destinationNode;
              const newNode = dest.isRoot()
                ? dest.before({
                    ...clonedTargetNode,
                    state: {
                      dropable: true
                    }
                  })
                : dest.hasChildren()
                ? dest.append(clonedTargetNode)
                : dest.after(clonedTargetNode);
              $vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: 'Note',
                text: `You're about to move ${newNode.text}. The ${targetNode.parent.text} will be empty which will automatically deteled. Are you sure you want to proceed?`,
                accept: () => {
                  targetNode.parent.remove();
                },
                cancel: () => {
                  newNode.remove();
                },
                acceptText: 'Yes, Continue!'
              });
              return false;
            }

            // if (destinationNode.hasChildren() && !destinationNode.isRoot()) {
            //   console.log('C')
            //   const prev = destinationNode.prev();
            //   if (prev.hasChildren()) prev.append(clonedTargetNode)
            //   else prev.after(clonedTargetNode)
            //   targetNode.remove();
            //   return false;
            // }

            console.log('onDragFinish', {
              targetNode,
              destinationNode,
              dropPosition
            });

            if (!targetNode.hasChildren() && (dropPosition === 'drag-on' || destinationNode.index() >= 0)) {
              targetNode.state('dropable', false);
            }

            return true;
          }
        }
      }
    };
  },
  computed: {
    tree() {
      if (!this.isMounted) return null;
      if (!this.$refs.tree) return null;
      return this.$refs.tree;
    },
    treeEmpty() {
      if (!this.isMounted) return true;
      if (!this.tree) return true;
      return this.tree.visibleModel.length === 0;
    }
  },
  methods: {
    autoFocus(node) {
      const findVxEditable = (refs, vm) => {
        const { $children } = vm;
        const nodeContent = $children.find(c => new RegExp(/vue-component-\s*(\d+)\s*-node-content/gm).test(c.$vnode.tag));

        const itemRow = nodeContent.$children.find(c => new RegExp(/vue-component-\s*(\d+)\s*-ItemRow/gm).test(c.$vnode.tag));
        for (let r = 0; r < refs.length; r++) {
          const ref = refs[r];
          for (let i = 0; i < itemRow.$children.length; i++) {
            const child = itemRow.$children[i];
            if (child.$vnode.data.ref === ref) return child;
          }
        }

        return null;
      };
      const vxEditable = findVxEditable(['childTitle', 'parentTitle'], node.vm);
      if (vxEditable) vxEditable.openEditable();
    },
    prependNode(node) {
      const newNode = node.prepend(new Node());
      if (Boolean(node.expanded) && Boolean(node.toggleExpand)) {
        if (!node.expanded()) node.toggleExpand();
      }
      this.$nextTick(() => {
        this.autoFocus(newNode);
      });
    },
    appendParentNode(node) {
      const newNode = node.append(
        new Node({
          text: 'Untitled Section',
          state: {
            expanded: true,
            dropable: true
          },
          children: [new Node()]
        })
      );
      if (Boolean(node.expanded) && Boolean(node.toggleExpand)) {
        if (!node.expanded()) node.toggleExpand();
      }
      this.$nextTick(() => {
        this.autoFocus(newNode);
      });
    },
    deleteNode(node) {
      return this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure? You're about to delete ${node.text}.`,
        accept: () => {
          node.remove();
        },
        acceptText: 'Yes, delete it!'
      });
    },
    async emitInput(value) {
      console.log(typeof value === 'object' && !value.id, value);
      if (typeof value === 'object' && !value.id) {
        console.log('HEEEY');
        await this.$vxPopup
          .open({
            attrs: {
              title: 'Create a test case',
              class: 'vx-popup--card-component'
            },
            config: {
              component: SingleMonitorForm,
              data: {
                ...value
              },
              config: {
                preventRedirect: true,
                interval: 'disabled',
                builder: {
                  mode: 'none'
                }
              }
            }
          })
          .then(async component => {
            console.log(component);
            window.$tree = this.tree;

            return await new Promise(resolve => {
              component.$on('submit', result => {
                console.log(result);
                const selection = this.tree.find({
                  text: /^New test case/,
                  state: {
                    visible: true,
                    selected: true
                  }
                })[0];

                if (selection) selection.data = result;
                component.$emit('close');
                resolve(result);
              });

              component.$on('cancel', () => resolve(null));
            });
          });
      }
      const items = this.tree.toJSON();
      const data = items.map(item => new TestSuite(item));
      console.log({ items, data });
      this.$emit('input', data);
    },
    inheritStateFromModel(data, model) {
      const { inheritStateFromModel } = this;
      function inherit(o, i) {
        return new Node({
          ...o,
          ...((o.items || []).length > 0
            ? {
                items: inheritStateFromModel(o.items, model[i]?model[i].children:{})
              }
            : {}),
          ...(model[i]
            ? {
                state: model[i].states
              }
            : {})
        });
      }

      return data.map(inherit);
    }
  },
  mounted() {},
  watch: {
    value(data) {
      const { tree, inheritStateFromModel } = this;
              console.log('data', data);
      tree.setModel(inheritStateFromModel(data, tree.visibleModel));
      console.log('tree', tree);
    }
  }
};
</script>
