<template>
  <div class="test-suits-results">
    <div v-if="!data" class="con-vs-alert con-vs-alert-primary con-icon" >
      <div class="vs-alert con-icon">
        <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
        <div class="flex items-center justify-between">
          <p>Test results not available.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <vue-apex-charts
        type="pie" height="350"
        :options="pieChart.chartOptions"
        :series="pieChart.series"></vue-apex-charts>
      
      <div class="text-center">
        <p class="font-semibold">{{ passedPercent }} Passed</p>
        <p class="text-disabled">{{ untested }}/{{ data.testCases }} untested ({{ untestedPercent }})</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  components: { VueApexCharts },
  props: {
    data: {
      type: Object,
      default() {
        return {
          testCases: 3,
          passed: 1,
          failed: 1,
          disabled: 1
        }
      }
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    untested() {  
      const { testCases, passed, failed, disabled } = this.data;
      const totalTestsProccessed = passed + failed + disabled;
      return testCases - totalTestsProccessed;
    },
    untestedPercent() {
      const { untested, data } = this
      const { testCases } = data;
      return Number(untested / testCases * 100).toFixed(1) + '%';
    },
    passedPercent() {
      const { testCases, passed } = this.data;
      return Number(passed / testCases * 100).toFixed(1) + '%';
    },
    pieChart() {
      const { data } = this;
      return {
        series: [data.passed, data.failed, data.disabled],
        chartOptions: {
          labels: ['Passed', 'Failed', 'Disabled'],
          colors: ['#28C76F', '#EA5455', '#A7A7A7'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      }
    }
  }
}
</script>