<template>
  <vx-card title="active">
    <template slot="title">
      <h5 class="font-semibold w-full">
        <vxEditable ref="parentTitle" v-model="item.name" placeholder="Enter title . . ." @input="updateTestSuite" />
      </h5>
    </template>
    <VxTabs ref="vxTabs">
      <VxTab icon="icon-briefcase" title="Section & Cases">
        <TestCaseBuilder v-model="(item || {}).items" @input="updateTestSuite" />
      </VxTab>
      <VxTab icon="icon-pie-chart" title="Latest Results">
        <TestSuitesResults :data="(item || {}).summary" />
      </VxTab>
    </VxTabs>
  </vx-card>
</template>

<script>
import TestCaseBuilder from '@/views/monitoring/components/test-suite-builder/Index';
import TestSuitesResults from '@/views/monitoring/components/TestSuitesResults';
import TestSuite from '@/entity/TestSuite';

export default {
  components: { TestCaseBuilder, TestSuitesResults },
  data() {
    return {
      item: new TestSuite()
    };
  },
  computed: {},
  methods: {
    async updateTestSuite() {
      const { $store, item } = this;
      const result = await $store.dispatch('monitors/updateTestItem', {
        data: {
          ...item,
          entity: undefined,
          summary: undefined
        }
      });
      console.log('updateTestSuite result ', result);
    }
  },
  async beforeCreate() {
    const item = await this.$store.dispatch('monitors/testItem', { id: this.$route.params.id });
    this.item = new TestSuite(item);
  }
};
</script>
